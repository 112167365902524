import React, { useContext } from "react";
import "./Services.css";
import Card from "../../components/Card/Card";
import frontend from "../../assets/images/Services/frontend.svg";
import backend from "../../assets/images/Services/backend.svg";
import consulting from "../../assets/images/Services/consulting.svg";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Resume from "../../assets/LuisMendez.pdf";
import { services } from "../../Portfolio";

export default function Services() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  const transition = {
    duration: 1,
    type: "spring",
  };

  if (!services.display) {
    return null;
  }

  return (
    <div className="services" id="services">
      <div className="awesome">
        <span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
        <span>services</span>
        <spane>
          Services I offer as a software developer to develop great ideas
          <br />
          UNDERSTAND_THIS ? stayHereUntilTheEnd() : pass
        </spane>
        <a href={Resume} target="_blank">
          <button className="button s-button">Open CV</button>
        </a>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }} />
      </div>

      <div className="cards">
        <motion.div
          initial={{ left: "25rem" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
        >
          <Card
            emoji={backend}
            heading={"Backend Development"}
            detail={"Python, C#, Django, ASP.NET Core, Java, SQL"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
        >
          <Card
            emoji={frontend}
            heading={"Frontend Development"}
            detail={"JavaScript, TypeScript, ReactJS, NextJS"}
          />
        </motion.div>
        <motion.div
          initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}
        >
          <Card
            emoji={consulting}
            heading={"Software Consulting"}
            detail={
              "Software verification and validation and project management"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <div className="blur s-blur2" style={{ background: "var(--purple)" }} />
      </div>
    </div>
  );
}
