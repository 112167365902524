import React from "react";
import Toggle from "../../components/Toggle/Toggle";
import "./Navbar.css";
import { Link } from "react-scroll";
import { navbar } from "../../Portfolio";

export default function Navbar() {
  if (!navbar.display) {
    return null;
  }

  return (
    <div className="n-wrapper" id="Navbar">
      <div className="n-left">
        <div className="n-name">Luis Mendez</div>
        <Toggle />
      </div>

      <div className="n-right">
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>
            {navbar.itemList.map(({ activeClass, to, title }, index) => (
              <li key={index}>
                <Link
                  activeClass={activeClass}
                  to={to}
                  spy={true}
                  smooth={true}
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Link to="contact" spy={true} smooth={true}>
          <button className="button n-button">Contact</button>
        </Link>
      </div>
    </div>
  );
}
