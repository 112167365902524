import React from "react";

import "./FloatingDiv.css";

export default function FloatingDiv({ img, text1, text2, width, height, alt }) {
  return (
    <div className="floatingDiv">
      <img src={img} width={width} height={height} alt={alt} />
      <span>
        {text1}
        <br />
        {text2}
      </span>
    </div>
  );
}
