import React, { useContext } from "react";
import "./Works.css";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { works } from "../../Portfolio";

export default function Works() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  if (!works.display) {
    return null;
  }

  return (
    <div className="works" id="works">
      <div className="w-left">
        <div className="awesome">
          <span style={{ color: darkMode ? "white" : "" }}>
            Works for All these
          </span>
          <span>Platforms & Services</span>
          <spane>
            Experience working on cloud process for teams.
            <br />
          </spane>
          <Link to="contact" smooth={true} spy={true}>
            <button className="button s-button">Hire Me</button>
          </Link>
          <div className="blur s-blur1" style={{ background: "#ABF1FF94" }} />
        </div>
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          {works.worksData.map(({ img, w, h, alt }, index) => (
            <div className="w-secCircle" key={index}>
              <img src={img} width={w} height={h} alt={alt} />
            </div>
          ))}
        </motion.div>
        <div className="w-backCircle blueCircle" />
        <div className="w-backCircle yellowCircle" />
      </div>
    </div>
  );
}
