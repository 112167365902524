import "./Certifications.css";
import { Fade } from "react-reveal";
import React from "react";
import { achievement } from "../../Portfolio";
import { themeContext } from "../../Context";
import AchievementCard from "../../components/AchievementCard/AchievementCard";

export default function certifications() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = React.useContext(themeContext);
  const darkMode = theme.state.darkMode;

  if (!achievement.display) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="achievements">
        <div className="achievement-main-div">
          <div className="achievement-header">
            <span style={{ color: darkMode ? "white" : "" }}>
              Achievement Section
            </span>
            <span>Certifications</span>
          </div>
          <div className="achievement-cards-div">
            {achievement.achievementsCards.map((card, i) => {
              return (
                <AchievementCard
                  key={i}
                  isDark={darkMode}
                  cardInfo={{
                    title: card.title,
                    description: card.subtitle,
                    image: card.image,
                    footer: card.footerLink,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
