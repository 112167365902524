import React, { useContext } from "react";
import { themeContext } from "../../Context";
import "./Experience.css";
import { experience } from "../../Portfolio";

export default function Experience() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  if (!experience.display) {
    return null;
  }

  return (
    <div className="experience" id="experience">
      {experience.experienceData.map(({ title, subTitle, content }, index) => (
        <div className="achievement" key={index}>
          <div
            className="circle"
            style={{ color: darkMode ? "var(--orange)" : "" }}
          >
            {content}
          </div>
          <span style={{ color: darkMode ? "white" : "" }}>{title} </span>
          <span>{subTitle}</span>
        </div>
      ))}
    </div>
  );
}
