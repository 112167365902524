import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { testimonial } from "../../Portfolio";

export default function Testimonial() {
  if (!testimonial.display) {
    return null;
  }

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
        <div className="blur t-blur1" style={{ background: "var(--purple)" }} />
        <div className="blur t-blur2" style={{ background: "skyblue" }} />
      </div>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {testimonial.testimonialData.map(({ img, review }, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={img} alt="" />
                <span>{review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
