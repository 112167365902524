import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { themeContext } from "../../Context";
import { portfolio } from "../../Portfolio";
import { Pagination } from "swiper";

export default function Portfolio() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  if (!portfolio.display) {
    return null;
  }

  return (
    <div className="portfolio" id="portfolio">
      <span style={{ color: darkMode ? "white" : "" }}>
        Big Public Projects
      </span>
      <span>Portfolio</span>

      <Swiper
        modules={[Pagination]}
        spaceBetween={100}
        grabCursor={true}
        pagination={{ clickable: true }}
        className="portfolio-slider"
        breakpoints={{
          480: { slidesPerView: 1 },
          768: { slidesPerView: 3 },
        }}
      >
        {portfolio.portfolioData.map(({ img, alt }, index) => (
          <SwiperSlide key={index}>
            <img src={img} alt={alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
