import React, { useContext } from "react";
import "./Intro.css";
import glassesimoji from "../../assets/images/Intro/emoji.svg";
import star from "../../assets/images/Intro/star.svg";
import crown from "../../assets/images/Intro/crown.svg";
import FloatingDiv from "../../components/FloatingDiv/FloatingDiv";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { intro } from "../../Portfolio";

export default function Intro() {
  const transition = { duration: 2, type: "spring" };
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  if (!intro.display) {
    return null;
  }

  return (
    <div className="Intro" id="Intro">
      <div className="i-left">
        <div className="i-name">
          <span style={{ color: darkMode ? "white" : "" }}>Hi! I am</span>
          <span>Luis Mendez</span>
          <span>
            Software Developer having experience building web applications and
            working with great teams.
          </span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
          <button className="button i-button">Contact me</button>
        </Link>

        <div className="i-icons">
          {intro.introData.map(({ aHref, target, rel, icon, alt }, index) => (
            <a href={aHref} target={target} rel={rel} key={index}>
              <img src={icon} width={100} height={100} alt={alt} />
            </a>
          ))}
        </div>
      </div>

      <div className="i-right">
        {intro.vectorImg.map(({ img, alt }, index) => (
          <img src={img} alt={alt} key={index} />
        ))}

        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          width={170}
          height={170}
          src={glassesimoji}
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatingDiv
            img={crown}
            width={124}
            height={134}
            text1="Web"
            text2="Developer"
            alt="Crown"
          />
        </motion.div>

        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          <FloatingDiv
            img={star}
            width={120}
            height={132}
            text1="Mobile"
            text2="Development"
            alt="Star"
          />
        </motion.div>

        <div className="blur" style={{ background: "rgb(238 210 255)" }} />
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        />
      </div>
    </div>
  );
}
