// contact section
// emailjs values
const SERVICE_ID = "service_47ag9d7";
const TEMPLATE_ID = "template_3js29sa";
const PUBLIC_KEY = "79ZQdDf_DX4VIpVKY";

// portfolio
const DEFAULT_WIDTH = 111;
const DEFAULT_HEIGHT = 119;

export { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY, DEFAULT_WIDTH, DEFAULT_HEIGHT };
