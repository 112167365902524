import React from "react";
import "./Footer.css";
import Wave from "../../assets/images/Footer/wave.svg";
import { footer } from "../../Portfolio";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

export default function Footer() {
  if (!footer.display) {
    return null;
  }

  return (
    <div className="footer">
      <img src={Wave} alt="Wave" style={{ width: "100%" }} />
      <div className="f-content">
        <SocialMedia />
        {/*TODO: add more info here. Responsive mode*/}
        <span className="span-footer">
          &copy; 2022 LuisMendezDev. All rights reserved.
        </span>
      </div>
    </div>
  );
}
