import google from "./assets/images/Certification/google.svg";
import michigan from "./assets/images/Certification/michigan.svg";
import Github from "./assets/images/Intro/github.svg";
import LinkedIn from "./assets/images/Intro/linkedin.svg";
import Mail from "./assets/images/Intro/mail.svg";
import Vector1 from "./assets/images/Intro/Vector1.svg";
import Vector2 from "./assets/images/Intro/Vector2.svg";
import Avatar from "./assets/images/Intro/intro.svg";
import bankingApp from "./assets/images/Portfolio/bankingApp.svg";
import administrativeSystem from "./assets/images/Portfolio/administrativeSystem.svg";
import mapsApp from "./assets/images/Portfolio/mapsApp.svg";
import ecommerce from "./assets/images/Portfolio/ecommerceNET.svg";
import profilePic1 from "./assets/images/Testimonial/profile1.jpg";
import profilePic2 from "./assets/images/Testimonial/profile2.jpg";
import profilePic3 from "./assets/images/Testimonial/profile3.jpg";
import profilePic4 from "./assets/images/Testimonial/profile4.jpg";
import Upwork from "./assets/images/Work/upwork.svg";
import DO from "./assets/images/Work/do.svg";
import Aws from "./assets/images/Work/aws.svg";
import Azure from "./assets/images/Work/azure.svg";
import Gcp from "./assets/images/Work/gcp.svg";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./globalValues";

// certification section
export const achievement = {
  title: "Certifications",
  subtitle: "",

  achievementsCards: [
    {
      title: "Technical Support Fundamentals",
      subtitle: "Certification granted by Google",
      image: google,
      footerLink: [
        {
          name: "Go to Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/VRAZUNX8QXWS",
        },
      ],
    },
    {
      title: "Python",
      subtitle: "Certification granted by Michigan University",
      image: michigan,
      footerLink: [
        {
          name: "Go to Certification",
          url: "https://www.coursera.org/account/accomplishments/certificate/73M9VFHNQ55F",
        },
      ],
    },
  ],
  display: true,
};

// experience section
export const experience = {
  experienceData: [
    {
      title: "years",
      subTitle: "Experience",
      content: "3+",
    },
    {
      title: "completed",
      subTitle: "Projects",
      content: "40+",
    },
    {
      title: "companies",
      subTitle: "Work",
      content: "6+",
    },
  ],
  display: true,
};

// intro section
export const intro = {
  introData: [
    {
      aHref: "https://github.com/dluismendezpy",
      target: "_blank",
      rel: "noreferrer",
      icon: Github,
      alt: "Luis Mendez Github",
    },
    {
      aHref: "https://www.linkedin.com/in/luismendez-dev/",
      target: "_blank",
      rel: "noreferrer",
      icon: LinkedIn,
      alt: "Luis Mendez LinkedIn",
    },
    {
      aHref: "mailto:info@luismendezdev.com",
      target: "",
      rel: "",
      icon: Mail,
      alt: "Luis Mendez Mail",
    },
  ],
  vectorImg: [
    {
      img: Vector1,
      alt: "Blue Vector",
    },
    {
      img: Vector2,
      alt: "Yellow Vector",
    },
    {
      img: Avatar,
      alt: "Avatar",
    },
  ],
  display: true,
};

// navbar section
export const navbar = {
  itemList: [
    {
      activeClass: "active",
      to: "Navbar",
      title: "Home",
    },
    {
      activeClass: "",
      to: "services",
      title: "Services",
    },
    {
      activeClass: "",
      to: "works",
      title: "Experience",
    },
    {
      activeClass: "",
      to: "portfolio",
      title: "Portfolio",
    },
    {
      activeClass: "",
      to: "testimonial",
      title: "Testimonial",
    },
  ],
  display: true,
};

// portfolio section
export const portfolio = {
  portfolioData: [
    {
      img: bankingApp,
      alt: "Banking App using React Native",
    },
    {
      img: administrativeSystem,
      alt: "Administrative system using Django and ReactJS",
    },
    {
      img: mapsApp,
      alt: "Maps App using React Native",
    },
    {
      img: ecommerce,
      alt: "Ecommerce system using .NET Core and ReactJs",
    },
  ],
  display: true,
};

// testimonial section
export const testimonial = {
  testimonialData: [
    {
      img: profilePic1,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
    {
      img: profilePic2,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
    {
      img: profilePic3,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
    {
      img: profilePic4,
      review:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex officiis molestiae quod tempora laudantium, cumque error a nisi placeat quae exercitationem, maiores reiciendis! Eaque dicta minima, iure maiores dolorum sed.",
    },
  ],
  display: false,
};

// works section
export const works = {
  worksData: [
    {
      img: Upwork,
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      alt: "Upwork",
    },
    {
      img: DO,
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      alt: "Digital Ocean",
    },
    {
      img: Aws,
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      alt: "Amazon Web Services",
    },
    {
      img: Azure,
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      alt: "Azure",
    },
    {
      img: Gcp,
      w: DEFAULT_WIDTH,
      h: DEFAULT_HEIGHT,
      alt: "Google Cloud Platform",
    },
  ],
  display: true,
};

// social media section
export const socialMediaLinks = {
  github: "https://github.com/dluismendezpy",
  linkedin: "https://www.linkedin.com/in/luismendez-dev/",
  gmail: "info@luismendezdev.com ",
  facebook: "https://www.facebook.com/profile.php?id=100054093457950",
  twitter: "https://twitter.com/luismendezpy",
  display: true,
};

// contact section
export const contact = {
  display: true,
};

// footer section
export const footer = {
  display: true,
};

// service section
export const services = {
  display: true,
};
